import './ReportGenerator.css';

import { fileOpen } from 'browser-fs-access';
import { useState } from 'react';
import { useMsal } from "@azure/msal-react";

import { generateReport } from '../api/backendApi';

export function ReportGenerator() {

  const [files, setFiles] = useState([]);
  const { instance } = useMsal();

  const OPTIONS = {
    multiple: true,
    extensions: ['.json']
  };

  async function selectFiles() {
    setFiles([...files, ...await fileOpen(OPTIONS)]);
  }

  function clear() {
    setFiles([]);
  }

  function retrieveReport() {
    generateReport(files, instance);
  }

  function getDisplayName(name) {
    return name.replace('.json', '').replaceAll('_', ' ');
  }

  return (
    <> 
      <h1>Rapportage voor Naregelingen</h1>
      <p>Selecteer één of meerdere bestanden en genereer vervolgens een rapport.</p>
      <button onClick={selectFiles}>Selecteer</button>
      <button disabled={files.length === 0} onClick={clear}>Clear</button>
      <button disabled={files.length === 0} onClick={retrieveReport}>Genereer Rapport</button>
      <div className='files'>
        {files.map(file => <span key={file.name}>{getDisplayName(file.name)}</span>)}
      </div>
    </>
  )
}