import { authFetch } from '../authFetch';

//TODO: name should be replaced by result from header
async function downloadFile(response, name) {
  const filename = name.split('_')[0] + '.docx';
  const data = await response.blob();
  console.log(data);
  const url = URL.createObjectURL(data);
  const a = document.createElement('a');
  document.body.appendChild(a);

  a.href = url;
  a.download = filename;
  a.setAttribute('style', 'display: none');
  a.click();

  window.URL.revokeObjectURL(url);
  a.remove();
}

async function listReports(files) {
  const reportInfo = [];
  await Promise.all(files.map(async (file) => {
    reportInfo.push(JSON.parse(await file.text()));
  }));
  return reportInfo;
}

export async function generateReport(files, msalInstance) {
  const result = await listReports(files);
  authFetch('/api/generate', result, msalInstance)
  .then((response) => downloadFile(response, files[0].name))
  .catch(err=>{
    console.log(err)
  });
}