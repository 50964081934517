import React from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { allowedRoles } from './authConfig';

export function MsalAuthenticator({ children }) {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) {
    if(!!allowedRoles.filter(role => accounts[0].idTokenClaims.roles.includes(role))) {
      return (<>{children}</>);
    } else {
      return (<p>Access not allowed</p>);
    }
  } else {
    instance.loginRedirect();
    return;
  }
}
