const getToken = async(msalInstance) => {
    if(msalInstance.getAllAccounts().length === 0) {
        return '';
    }
    const response = await msalInstance.acquireTokenSilent({
        account: msalInstance.getAllAccounts()[0],
        scopes: ['0cf4463d-fa4d-44d6-b0c9-794af5f2c215/app']
    });
    return response.accessToken;
}

export const authFetch = async (path, body, msalInstance) => {
    const token = await getToken(msalInstance);
    return fetch(path, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
    });
}