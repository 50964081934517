export const msalConfig = {
    auth: {
        clientId: "0cf4463d-fa4d-44d6-b0c9-794af5f2c215",
        authority: "https://login.microsoftonline.com/84d357d7-0c40-4e87-9f18-50c1f99e48ba", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: "/",
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

export const allowedRoles = ['chn.software'];