import './App.css';

import Header from './Header';
import { ReportGenerator } from './ReportGenerator';

function App() {
  return (
    <div>
      <Header/>
      <div className='content'>
        <ReportGenerator />
      </div>
    </div>
  );
}

export default App;
