import './Header.css';
import logo from '../assets/chainless_logo.png'; 

function Header() {
    return (
        <div className='header'>
            <img className='chn-logo' alt='' src={logo} />
            <div className='tabs'>
                <div className='tab-selected'>
                    <span>Rapportage</span>
                </div>
            </div>
        </div>
    )
}

export default Header;