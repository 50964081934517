import React from 'react';
import ReactDOM from 'react-dom/client';

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

import './index.css';
import App from './components/App';
import { MsalAuthenticator } from './MsalAuthenticator';

export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticator>
        <App />
      </MsalAuthenticator>
    </MsalProvider>
  </React.StrictMode>
);
